<template>
  <SmartLink v-if="ready" :to="to" :href="href" :nick="nick" :soft="soft">
    <picture class="d-flex justify-center align-center">
      <source
        v-for="(item, ind) in preloaded.sources"
        :key="ind"
        :srcset="item.srcset"
        :media="item.media"
        :type="item.type"
        :width="item.width"
        :height="item.height"
      />
      <img v-bind="img" :style="style" />
    </picture>
  </SmartLink>
</template>
<script setup>
const props = defineProps({
  preloaded: { type: Object, default: null },
  alt: { type: String, default: "Offer" },
  to: { type: String, default: "" },
  href: { type: String, default: "" },
  soft: { type: String, default: "" },
  nick: { type: String, default: "" },
});

const style = ref(props?.preloaded?.img?.style);
const img = ref(_.omit(props?.preloaded?.img, ["style"]));
const ready = props.preloaded && (!!props.nick || !!props.soft || !!props.to || !!props.href);
</script>
